import { Controller } from '@hotwired/stimulus';
import { post } from '../utils/axios_request';

export default class extends Controller {
  static targets = ['sendTime', 'hoursBefore', 'sendTimeWrapper',
                    'hoursBeforeWrapper', 'form'];

  static values = {
    dateFormat: String,
    timeFormat: String,
    previewPath: String
  };

  static classes = ['hidden'];

  connect() {
    $(this.sendTimeTarget).datetimepicker({
      dateFormat: this.dateFormatValue,
      timeFormat: this.timeFormatValue,
      controlType: 'select',
      oneLine: true,
      minDate: 0
    });
  }

  swapSendTime() {
    if (this.sendTimeWrapperTarget.classList.contains(this.hiddenClass)) {
      this.hoursBeforeWrapperTarget.classList.add(this.hiddenClass);
      this.sendTimeWrapperTarget.classList.remove(this.hiddenClass);
    } else {
      this.hoursBeforeWrapperTarget.classList.remove(this.hiddenClass);
      this.sendTimeWrapperTarget.classList.add(this.hiddenClass);
    }
  }

  clearHiddenInput() {
    if (this.sendTimeWrapperTarget.classList.contains(this.hiddenClass)) {
      this.sendTimeTarget.value = null;
    } else {
      this.hoursBeforeTarget.value = null;
    }
  }

  emailPreview(e) {
    e.preventDefault();
    const subject = this.formTarget.querySelector('#override_subject').value;
    const message = window.frEditors.html.get();
    post(this.previewPathValue, { override: { subject, message } })
      .then((response) => {
        // eslint-disable-next-line no-eval
        eval(response.data);
      });
  }
}
