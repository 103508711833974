import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    regex: String
  };

  connect() {
    this.inputRegex = new RegExp(this.regexValue);
  }

  // ACTIONS

  inputTyping(e) {
    if (!this.inputRegex.test(e.key)) {
      e.preventDefault();
    }
  }

  inputPasting(e) {
    e.preventDefault();
    return false;
  }
}
