import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['lineWithTag', 'floater', 'prevs', 'nexts', 'previewSpan', 'tagValueInput'];

  static values = {
    index: Number
  };

  static classes = ['displayNone', 'active', 'underline', 'strikethrough'];

  initialize() {
    // hide previous button on first floater
    if (this.hasPrevsTarget) {
      this.prevsTargets[0].classList.add(this.displayNoneClass);
    }
    // hide next button on last floater
    if (this.hasNextsTarget) {
      this.nextsTargets[this.nextsTargets.length - 1].classList.add(this.displayNoneClass);
    }
    this.createAndFillPreviewSpans();
  }

  indexValueChanged() {
    this.showField();
  }

  // ACTIONS

  activateLine(e) {
    if (e.target.closest('.js-floater')) return; // do not activate line on floater div click

    const target = e.target.closest('.js-has-tag');
    if (target.classList.contains(this.activeClass)) {
      this.hideAllFields();
    } else {
      /* in case user clicks multiple times on same Line the indexValueChanged callback
         would not trigger because index Value would be the same.
         So we trigger the showField method manually here
      */
      const newIndexValue = Array.from(this.lineWithTagTargets).indexOf(target);
      if (newIndexValue === this.indexValue) {
        this.showField();
      } else {
        this.indexValue = newIndexValue;
      }
    }
  }

  previousField() {
    this.indexValue -= 1;
  }

  nextField() {
    this.indexValue += 1;
  }

  closeField() {
    this.hideAllFields();
  }

  focusTagValue(e) {
    const i = Array.from(this.tagValueInputTargets).indexOf(e.target);
    const tributeSpan = this.previewSpanTargets[i].nextSibling;
    this.previewSpanTargets[i].classList.add(this.underlineClass);
    tributeSpan.classList.add(this.underlineClass, this.strikethroughClass);
  }

  focusoutTagValue(e) {
    const i = Array.from(this.tagValueInputTargets).indexOf(e.target);
    const tributeSpan = this.previewSpanTargets[i].nextSibling;
    this.previewSpanTargets[i].classList.remove(this.underlineClass);
    tributeSpan.classList.remove(this.underlineClass, this.strikethroughClass);
  }

  changeTagValue(e) {
    const i = Array.from(this.tagValueInputTargets).indexOf(e.target);
    const icon = '<i class="fas fa-user checked-icon"></i>';
    const inputValue = e.target.value.trim();
    const previewElement = this.previewSpanTargets[i];
  
    if (inputValue) {
      previewElement.innerHTML = previewElement.innerHTML.includes(icon)
        ? icon + inputValue
        : inputValue;
    } else {
      const name = previewElement.getAttribute('name').replace(/_/g, ' ');
      previewElement.innerHTML = previewElement.innerHTML.includes(icon)
        ? icon + name
        : name;
    }
  }

  // MUTATE DOM

  createAndFillPreviewSpans() {
    let totalTags = 0;
  
    Array.from(this.lineWithTagTargets).forEach((el) => {
      const placeholder = el.textContent.match(/\{(.*?)\}/)[1].replace(/_/g, " ");
      const autofilled = el.dataset.autofilled
      Array.from(el.querySelectorAll('.fr-deletable.fr-tribute')).forEach((span) => {
        const newNode = document.createElement('span');
        newNode.setAttribute(`data-${this.identifier}-target`, 'previewSpan');
        newNode.setAttribute('name', span.textContent.replace(/{|}/g, ""));
  
        const inputValue = this.tagValueInputTargets[totalTags].value;
        const changeColor = inputValue && autofilled == 'true'
        newNode.classList.add(changeColor ? 'tags-color-auto' : 'tags-color');
        newNode.innerHTML = inputValue ? inputValue : placeholder;
  
        span.parentNode.replaceChild(newNode, span);
        totalTags++;
      });
    });
  }

  filledByCandidate(e) {
    const checkbox = e.target;
    const lineWithTag = checkbox.closest('.js-has-tag');
    const icon = document.createElement('i');
    icon.classList.add('fas', 'fa-user', 'checked-icon');
    const icon2 = document.createElement('i');
    icon2.classList.add('fas', 'fa-user', 'checked-icon2');
    
    let span, label;

    if (lineWithTag.querySelector('.tags-color')) {
      span = lineWithTag.querySelector('.tags-color');
      label = lineWithTag.querySelector('.tags');
    } else {
      span = lineWithTag.querySelector('.tags-color-auto');
      label = lineWithTag.querySelector('.tags-bg-auto');
      label = label.querySelector('.tags-color-auto')
    }

    if (checkbox.checked) {
      span.insertBefore(icon, span.firstChild);
      label.insertBefore(icon2, label.firstChild);
    } else {
      const existingIcon = lineWithTag.querySelector('.checked-icon');
      if (existingIcon) {
        existingIcon.parentNode.removeChild(existingIcon);
        const labelIcon = label.querySelector('.checked-icon2');
        if (labelIcon) {
          labelIcon.parentNode.removeChild(labelIcon);
        }
      }
    }
  }

  showField() {
    this.hideAllFields();
    if (this.indexValue < 0) return;
    if (this.floaterTargets[this.indexValue] === undefined) return;

    this.floaterTargets[this.indexValue].classList.remove(this.displayNoneClass);
    this.lineWithTagTargets[this.indexValue].classList.add(this.activeClass);
    this.floaterTargets[this.indexValue].scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  hideAllFields() {
    Array.from(this.floaterTargets).forEach((el, i) => {
      el.classList.add(this.displayNoneClass);
      this.lineWithTagTargets[i].classList.remove(this.activeClass);
    });
  }
}
