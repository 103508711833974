import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['foldedList', 'unfoldLink', 'foldLink'];

  static classes = ['hidden'];

  // ACTIONS

  toggleFolding() {
    this.unfoldLinkTarget.classList.toggle(this.hiddenClass);
    this.foldLinkTarget.classList.toggle(this.hiddenClass);
    $(this.foldedListTarget).slideToggle();
  }
}
