import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox'];

  static values = {
    minChecked: { type: Number, default: 0 }
  };

  static classes = ['active'];

  connect() {
    this.checkboxTargets.forEach((target) => {
      $(target).buttonbox({
        activeClass: this.activeClass,
        callback: this.buttonboxCallback.bind(this)
      });
    });
  }

  buttonboxCallback(_, checked) {
    const amountOfActive = this.checkboxTargets.filter(
      elem => elem.nextSibling.classList.contains(this.activeClass)
    ).length;
    return amountOfActive > this.minCheckedValue || checked;
  }
}
