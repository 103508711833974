import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['minDist', 'maxDist', 'wrongDist'];

  static classes = ['wrong', 'hidden'];

  // ACTIONS

  validateDist(elem) {
    this.checkIfInRange(elem);
    if (this.minDistTarget.value === '' || this.maxDistTarget.value === ''
      || parseInt(this.minDistTarget.value, 10) <= parseInt(this.maxDistTarget.value, 10)) {
      this.minDistTarget.classList.remove(this.wrongClass);
      this.maxDistTarget.classList.remove(this.wrongClass);
      this.wrongDistTarget.classList.add(this.hiddenClass);
      this.searchEvent();
    } else {
      this.minDistTarget.classList.add(this.wrongClass);
      this.maxDistTarget.classList.add(this.wrongClass);
      this.wrongDistTarget.classList.remove(this.hiddenClass);
    }
  }

  preventOnEnter(e) {
    if (e.keyCode === 13) e.preventDefault();
  }

  searchEvent() {
    this.dispatch('search');
  }

  // PRIVATE

  checkIfInRange(elem) {
    const minValue = parseInt(elem.target.min, 10) || 0;
    const maxValue = parseInt(elem.target.max, 10) || 50000;
    if (elem.target.value < minValue) {
      elem.target.value = '';
    } else if (elem.target.value > maxValue) {
      elem.target.value = maxValue;
    }
  }
}
