import { Controller } from '@hotwired/stimulus';
import { destroy } from '../utils/axios_request';

export default class extends Controller {
  static values = {
    path: String
  }

  unassignTag() {
    destroy(this.pathValue).then((response) => {
      if (response.status === 204) {
        this.element.outerHTML = '';
      }
    });
  }
}
