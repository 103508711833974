import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  check () {
    if (this.element.classList.contains('selected')) {
      this.element.classList.remove('selected');
    } else {
      this.element.classList.add('selected');
    }
  }
}
