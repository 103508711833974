import { Controller } from '@hotwired/stimulus';
import { post } from '../utils/axios_request';

export default class extends Controller {
  static targets = ['tagName', 'statusMessages'];

  static values = {
    url: String
  };

  // ACTIONS

  inputTyping(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.addCustomTag();
    }
  }

  addCustomTag() {
    this.clearStatusMessages();
    const newTagName = this.tagNameTarget.value;
    post(this.urlValue, { custom_contract_tag: { name: newTagName } }).then((response) => {
      this.tagNameTarget.value = '';
      this.addStatusMessage(response.data.notice);
      const newTag = response.data.custom_contract_tag.name;
      let currentTags = JSON.parse(localStorage.getItem('tagsToAdd'));
      if (currentTags) {
        currentTags.push(newTag);
        localStorage.setItem('tagsToAdd', JSON.stringify(currentTags));
      } else {
        localStorage.setItem('tagsToAdd', JSON.stringify([newTag]));
      }
      window.tribute.append(0, [
        { key: `{${newTag}}`, value: `{${newTag}}` }
      ]);
    }).catch((error) => {
      this.addStatusMessage(error.response.data.errors);
    });
  }

  // PRIVATE

  clearStatusMessages() {
    this.statusMessagesTarget.innerHTML = '';
  }

  addStatusMessage(msg) {
    this.statusMessagesTarget.innerHTML = msg;
  }
}
