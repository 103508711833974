import { Controller } from '@hotwired/stimulus';
import { get } from '../utils/axios_request';

export default class extends Controller {
  static targets = [
    'streetInput', 'cityInput', 'postalInput', 'countrySelect', 'stateSelect',
    'logoInput', 'statesRoot'
  ];

  static values = {
    url: String
  }

  // ACTIONS

  copy() {
    get(this.urlValue).then((resp) => {
      const org = resp.data.organization;
      this.streetInputTarget.value = org.street_name;
      this.cityInputTarget.value = org.city;
      this.postalInputTarget.value = org.postal_code;
      this.countrySelectTarget.value = org.country_code;
      // change country method of another controller makes server call, we have to chain promise
      this.statesSelectController.changeCountry().then(() => {
        this.stateSelectTarget.value = org.state_code;
      });

      // this value goes to backend after form submit
      this.logoInputTarget.value = org.logo_url;
      // those values are purely for image preview to appear
      org.jq_upload.image_cache = org.logo_url;
      $('.js-fileupload').trigger('fileuploaddone', { result: JSON.stringify([org.jq_upload]) });
    });
  }

  // PRIVATE

  get statesSelectController() {
    return this.application.getControllerForElementAndIdentifier(
      this.statesRootTarget, 'states-select'
    );
  }
}
