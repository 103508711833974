import { Controller } from '@hotwired/stimulus';
import { destroy } from '../utils/axios_request';

export default class extends Controller {
  static values = {
    path: String
  }

  close_notification() {
    destroy(this.pathValue);
  }
}
