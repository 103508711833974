import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';
import Noty from 'noty';
import { post } from '../utils/axios_request';

export default class extends Controller {
  static targets = [
    'subjectInput', 'previewContainer', 'sublayer', 'subjectPreview', 'messagePreview', 'form', 'attachmentsPreview'
  ];

  static values = {
    previewPath: String,
    translations: Object
  };

  static classes = ['displayNone', 'fadeOut', 'noButton', 'yesButton'];

  // ACTIONS

  showPreview() {
    const timeSlots = [];
    $('.time-slot').each(function() {
      var $slot = $(this);
      var date = $slot.find('.flatpickr-input')[0].value;
      var startTime = $slot.find('#start-time').val();
      var endTime = $slot.find('#end-time').val();

      $slot.find('[name="slots[][application_ids][]"]').each(function() {
        timeSlots.push({ application_id: this.value, date: date, start_time: startTime, end_time: endTime})
      });
    });

    const formArrayData = Array.from(new FormData(this.formTarget));
    const roundsArr = formArrayData.filter(function(a) { return a[0].includes("round[") });
    const rounds = roundsArr.map((e) => e[0].slice(6, -1))
    const subject = this.subjectInputTarget.value;
    const message = window.frEditors.html.get();
    const files = document.querySelector('input[name="file_ids"]');
    const file_ids = files ? JSON.parse(files.value) : [];
    const requestedDocuments= [...document.querySelectorAll('input[name="documents[request_documents][]"]')].map(input => input.value);
    const $container = $('#email-previews-js')

    post(this.previewPathValue, { override: { subject, message, file_ids, requestedDocuments }, rounds, time_slots: timeSlots }).then((response) => {
      if (response.data.length > 0) {
        $container.html(this.emailPreviewContent(response.data));
        this.subjectPreviewTarget.innerHTML = response.data[0].subject;
        // Here I need to add code to display Attachments, but I don't know how to do it
        this.attachmentsPreviewTarget.innerHTML = this.attachmentsPreviewContent(response.data[0].files);
        if (requestedDocuments && requestedDocuments.length > 0) {
          this.attachmentsPreviewTarget.innerHTML = this.requestDocumentsPreviewContent(requestedDocuments);
        }
      } else {
        this.subjectPreviewTarget.innerHTML = "";
        $container.html('<p>There is no candidates to email. Selected group contains only artificial candidates.</p>');
      }

      this.showPreviewLayer();
    });
  }

  backToEdit() {
    this.hidePreviewLayer();
  }

  confirmSendEmail() {
    const n = new Noty({
      type: 'warning',
      text: this.translationsValue.warning,
      theme: 'semanticui',
      layout: 'center',
      closeWith: ['button'],
      buttons: [
        Noty.button(this.translationsValue.no, this.noButtonClass, () => n.close()),
        Noty.button(this.translationsValue.yes, this.yesButtonClass, () => {
          n.close();
          this.sendEmail();
        })
      ]
    });
    n.show();
  }

  // PRIVATE

  attachmentsPreviewContent(files) {
    if (!files) {
      return '';
    }
    let filesHtml = '<div class="wrapper-filelist"><div class="row">';
    files.forEach(file => {
      filesHtml += `
      <div class="col-6">
        <div class="file-item">
          <div class="file-item-text">
            <i class="fas fa-file file-item-text-icon"></i>
            <span>${file.name}</span>
          </div>
        </div>
      </div>
      `;
    });
    filesHtml += '</div></div>';
    return filesHtml;
  }

  requestDocumentsPreviewContent(documentNames) {
    if (!documentNames) {
      return '';
    }
    let requestedDocumentsHtml = '<div class="wrapper-filelist"><div class="row"><div class="col-6"><h5>Requested Documents List</h5></div>';
    documentNames.forEach(document => {
      requestedDocumentsHtml += `
      <div class="col-6">
        <div class="file-item">
          <div class="file-item-text">
            <span>${document}</span>
          </div>
        </div>
      </div>
      `;
    });
    requestedDocumentsHtml += '</div></div>';
    return requestedDocumentsHtml;
  }

  emailPreviewContent(dataArray) {
    const EmailPreviewTemplate = ({ full_name, message, index }) => `
      <div class="card">
        <div class="card-header" id="heading-${index}">
          <h6 class="mb-0">
            <button aria-controls="collapse-${index}" aria-expanded="${index == 0}" class="btn btn-link btn-block text-left full-name-js" data-target="#collapse-${index}" data-toggle="collapse" type="button">
              ${full_name}
            </button>
          </h6>
        </div>
        <div aria-labelled-by="heading-one" class="collapse ${index == 0 && 'show'}" data-parent="#email-previews-js" id="collapse-${index}">
          <div class="card-body">
            ${message}
          </div>
        </div>
      </div>
    `;

    return dataArray.map(EmailPreviewTemplate).join('');
  }

  showPreviewLayer() {
    $('.modal-dialog')[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.sublayerTarget.classList.toggle(this.displayNoneClass);
    this.previewContainerTarget.classList.toggle(this.fadeOutClass);
    $('.modal-body').height($(this.previewContainerTarget).height());
  }

  hidePreviewLayer() {
    $('.modal-body').height('auto');
    this.previewContainerTarget.classList.toggle(this.fadeOutClass);
    this.sublayerTarget.classList.toggle(this.displayNoneClass);
    $('.fr-box')[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  sendEmail() {
    this.hidePreviewLayer();
    if (this.formTarget.dataset.remote) {
      Rails.fire(this.formTarget, 'submit');
    } else {
      this.formTarget.submit();
    }
  }
}
