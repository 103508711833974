import { Controller } from '@hotwired/stimulus';
import { get } from '../utils/axios_request';

export default class extends Controller {
  static targets = ['countrySelect', 'statesSelect', 'statesWrapper'];

  static values = {
    url: String,
    hr: Boolean
  };

  static classes = ['hidden'];

  connect() {
    if (this.countrySelectTarget.value !== '') {
      this.statesWrapperTarget.classList.remove(this.hiddenClass);
    }
  }

  // ACTIONS

  changeCountry() {
    const countryCode = this.countrySelectTarget.value;
    if (countryCode === '') {
      this.statesWrapperTarget.classList.add(this.hiddenClass);
      return Promise.reject(new Error('No states to pull'));
    }
    return get(this.urlValue, { country_code: countryCode, hr: this.hrValue }).then((response) => {
      if (response.data.collection.length > 0) {
        this.removeAllStates();
        this.addStates(response.data.collection);
        this.statesWrapperTarget.classList.remove(this.hiddenClass);
      } else {
        this.statesWrapperTarget.classList.add(this.hiddenClass);
      }
    });
  }

  // PRIVATE

  removeAllStates() {
    const statesCount = this.statesSelectTarget.options.length;
    for (let i = 0; i < statesCount; i += 1) {
      this.statesSelectTarget.options.remove(0);
    }
  }

  addStates(states) {
    states.forEach((state) => {
      this.statesSelectTarget.options.add(new Option(state[1], state[0]));
    });
  }
}
