import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['subjectInput'];

  static values = {
    templates: Array,
    index: Number
  }

  connect() {
    this.templatesParsed = this.templatesValue.map(t => JSON.parse(t));
  }

  changeTemplate(e) {
    this.handleChange(e, false);
  }

  changeTagsTemplate(e) {
    this.handleChange(e, true);
  }

  handleChange(e, contractEditor) {
    const select = e.target;
    const templateId = parseInt(select.options[select.selectedIndex].value, 10);
    const template = this.findTemplate(templateId);
    let singleContract = localStorage.getItem('singleContract')
    if (template === undefined) return;

    if (this.hasSubjectInputTarget) {
      this.subjectInputTarget.value = template.subject;
    }

    const editor = this.hasIndexValue ? window.frEditors[this.indexValue] : window.frEditors;

    if (singleContract == 'true' || !contractEditor) {
      editor.html.set(this.hasIndexValue ? template.message : template.message.replace(/\r\n/g, '<br>'));
    } else {
      editor.html.set(this.hasIndexValue ? template.filled_body : template.filled_body.replace(/\r\n/g, '<br>'));
    }

    const options = template.options || {};

    // Create a new js event with the detail of the selected template attachments
    const templateChangeEvent = new CustomEvent('templateChange', {
      detail: options,
    });

    // Dispatch the event
    window.dispatchEvent(templateChangeEvent);
  }

  findTemplate(templateId) {
    return this.templatesParsed.filter(t => t.value === templateId)[0];
  }
}
