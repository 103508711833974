import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'markedForSyncInput'];

  // ACTIONS

  chosen(e) {
    Array.from(this.inputTargets).forEach((input) => {
      input.value = false;
    });
    Array.from(this.inputTargets).forEach((input, index) => {
      if (e.target.value === input.dataset.id) {
        input.value = true;
        const syncInput = this.markedForSyncInputTargets[index];
        if (!syncInput.checked) {
          this.markedForSyncInputTargets[index].click();
        }
      }
    });
  }
}
