import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger', 'element'];

  static classes = ['displayNone'];

  connect() {
    this.getSelectedAndToggleElements();
    // if there are no trigger targets in DOM, elements stay in their default state
  }

  // ACTIONS

  changeVisibility() {
    this.getSelectedAndToggleElements();
  }

  // PRIVATE

  getSelectedAndToggleElements() {
    Array.from(this.triggerTargets).forEach((el) => {
      const isCheckbox = el.dataset.type === 'checkbox';
      if (isCheckbox || el.checked) {
        let visibilityValue;
        let shouldConvert = false;
        if (isCheckbox) {
          visibilityValue = el.checked;
          shouldConvert = true;
        } else {
          visibilityValue = el.value;
        }
        this.toggleVisibility(visibilityValue, shouldConvert);
      }
    });
  }

  toggleVisibility(selected, convert) {
    Array.from(this.elementTargets).forEach((el) => {
      let { condition } = el.dataset;
      if (convert) {
        condition = (condition === 'true');
      }
      if (condition === selected) {
        el.classList.remove(this.displayNoneClass);
      } else {
        el.classList.add(this.displayNoneClass);
      }
    });
  }
}
