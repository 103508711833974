import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['fileInput', 'validationStatus', 'fileName', 'errorMessages'];

  // CONTROLLER INIT

  connect() {
    this.checkSizeAndExtension = window.FileUpload.checkSizeAndExtension;
  }

  // ACTIONS

  triggerFileSelection() {
    this.fileInputTarget.click();
  }

  fileUploaded() {
    if (!this.isValid()) return;
    this.setValidationStatus('');
    this.displayFileName();
  }

  // HELPERS

  isValid() {
    const result = this.checkSizeAndExtension(this.fileInputTarget);
    if (result.hasErrors) {
      this.setValidationStatus(this.getErrorMessage(result.errorId));
      this.clearLocalInput();
      return false;
    }
    return true;
  }

  getFileName() {
    return this.fileInputTarget.files[0].name;
  }


  getErrorMessage(errorId) {
    return document.getElementById(errorId).textContent
      .replace(/%\(file\)/, this.getFileName());
  }

  // DOM-MODIFIERS

  setValidationStatus(value) {
    this.validationStatusTarget.textContent = value;
  }

  clearLocalInput() {
    this.fileInputTarget.value = '';
  }

  displayFileName() {
    this.fileNameTarget.textContent = this.fileInputTarget.value.replace(/C:\\fakepath\\/i, '');
  }
}
