import { Controller } from '@hotwired/stimulus';
import u from 'umbrellajs';

export default class extends Controller {
  connect() {
    this.createCounter();
  }

  createCounter() {
    if (u(this.element).siblings('.counter').length) return;

    const target = u(this.element);
    target.parent().addClass('contains-counter');
    const inputLimit = target.attr('maxlength');
    $(this.element).characterCounter({
      limit: inputLimit,
      counterFormat: '%1',
      increaseCounting: false
    });
  }
}
