import { Controller } from '@hotwired/stimulus';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import './cropper_controller.scss';
import { putCacheImage } from '../utils/axios_request';

export default class extends Controller {
  static targets = [
    'removeImageInput', 'removeButton', 'imageCacheInput', 'fileInput', 'modal',
    'image', 'preview', 'validationStatus', 'croppedImagePreview'
  ];

  static values = {
    uploadUrl: String
  };

  static classes = ['displayNone'];

  initialize() {
    $(this.modalTarget).on('shown.bs.modal', () => {
      const self = this;
      this.previewReady = false;
      this.cropper = new Cropper(this.imageTarget, {
        aspectRatio: 1,
        viewMode: 3,
        ready() {
          self.onCropperReady(this);
        },
        crop: () => this.refreshPreview()
      });
    });
    $(this.modalTarget).on('hidden.bs.modal', () => {
      this.clearCropperAndPreviews();
    });
  }

  // ACTIONS

  selectImage() {
    this.fileInputTarget.click();
  }

  imageSelected(e) {
    this.setValidationStatus('');
    const file = e.target.files[0];
    const { hasErrors, errorId } = window.FileUpload.checkSizeAndExtension(e.target);
    if (hasErrors) {
      this.setValidationStatus(this.getErrorMessage(errorId));
      this.clearFileInput();
    } else {
      this.originalFilename = file.name;
      this.showPreview(file);
    }
  }

  removeImage() {
    this.removeImageInputTarget.value = true;
    this.croppedImagePreviewTarget.src = this.croppedImagePreviewTarget.dataset.fallbackUrl;
    this.removeButtonTarget.classList.add(this.displayNoneClass);
  }

  finalizeCropping() {
    this.cropper.getCroppedCanvas().toBlob((blob) => {
      putCacheImage(this.uploadUrlValue, blob, this.originalFilename).then((response) => {
        this.removeButtonTarget.classList.remove(this.displayNoneClass);
        this.removeImageInputTarget.value = false;
        const cache = response.data[0].image_cache;
        const croppedPreviewUrl = response.data[0].thumbnail_url;
        this.imageCacheInputTarget.value = cache;
        this.croppedImagePreviewTarget.src = croppedPreviewUrl;
        // clear file input so that uncropped version is not submitted
        // cropped version in submitted via cache input field
        this.clearFileInput();
        $(this.modalTarget).modal('hide');
      }).catch((error) => {
        this.setValidationStatus(error.response.data.error);
        this.clearFileInput();
      });
    });
  }

  // PRIVATE

  showPreview(file) {
    const reader = new FileReader();
    reader.onload = () => { this.openCropperModal(reader.result) };
    reader.readAsDataURL(file);
  }

  openCropperModal(srcUrl) {
    this.imageTarget.src = srcUrl;
    $(this.modalTarget).modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  onCropperReady(img) {
    const clone = img.cloneNode();
    clone.className = '';
    clone.style.cssText = (
      'display: block;'
      + 'width: 100%;'
      + 'min-width: 0;'
      + 'min-height: 0;'
      + 'max-width: none;'
      + 'max-height: none;'
    );
    Array.from(this.previewTargets).forEach((p) => {
      p.appendChild(clone.cloneNode());
    });
    this.previewReady = true;
    this.refreshPreview();
  }

  refreshPreview() {
    if (!this.previewReady) {
      return;
    }
    const data = this.cropper.getData();
    const imageData = this.cropper.getImageData();
    Array.from(this.previewTargets).forEach((p) => {
      // calculations taken from cropper js examples
      const previewImage = p.children[0];
      const previewWidth = p.offsetWidth;
      const previewHeight = p.offsetWidth; // square ratio
      const imageScaledRatio = data.width / previewWidth;

      p.style.height = previewHeight + 'px';
      previewImage.style.width = imageData.naturalWidth / imageScaledRatio + 'px';
      previewImage.style.height = imageData.naturalHeight / imageScaledRatio + 'px';
      previewImage.style.marginLeft = -data.x / imageScaledRatio + 'px';
      previewImage.style.marginTop = -data.y / imageScaledRatio + 'px';
    });
  }

  clearCropperAndPreviews() {
    this.cropper.destroy();
    this.cropper = null;
    Array.from(this.previewTargets).forEach((p) => {
      p.innerHTML = '';
    });
  }

  getFileName() {
    return this.fileInputTarget.files[0].name;
  }

  getErrorMessage(errorId) {
    return document.getElementById(errorId).textContent
      .replace(/%\(file\)/, this.getFileName());
  }

  setValidationStatus(value) {
    this.validationStatusTarget.classList.remove(this.displayNoneClass);
    if (value === '') {
      this.validationStatusTarget.classList.add(this.displayNoneClass);
    }
    this.validationStatusTarget.textContent = value;
  }

  clearFileInput() {
    this.fileInputTarget.value = '';
  }
}
