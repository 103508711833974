import { Controller } from '@hotwired/stimulus';

const pwdRgx = /^(.{0,7}|[^\p{Ll}]{1,}|[^\p{Lu}\p{Lt}]{1,}|[^\d]{1,}|[^\p{P}\p{S}]{1,})$/u;
/* Breakdown of Regex - match negatives and connect with OR

.{0,7} - matches if password has between 0 to 7 characters.
[^\p{Ll}]{1,} - matches if no lower case is found (all languages)
[^\p{Lu}\p{Lt}]{1,} - matches if no upper case is found (all languages)
[^\d]{1,} - matches if no number is found (between [0-9] - only arabic numbers)
[^\p{P}\p{S}]{1,} - matches if no punctuation or symbol is found (cover majority of special chars)
As per client request allow spaces in pwd.
*/


export default class extends Controller {
  static targets = ['field'];

  static values = {
    requiredError: String,
    emailFormatError: String,
    mustMatchError: String,
    passwordFormatError: String
  };

  static classes = ['displayNone', 'error'];

  connect() {
    this.setupRules();
    this.element.setAttribute('novalidate', true);
  }

  setupRules() {
    this.rules = {
      required: field => (field.type === 'checkbox' ? field.checked : Boolean(field.value.trim())),
      emailFormat: field => /^[^@]+@[^@]+$/.test(field.value),
      mustMatch: (field, dataId) => document.querySelector(`#${dataId}`).value === field.value,
      // https://stackoverflow.com/a/70126469 use negative approach with ORs and negate function result
      passwordFormat: field => !pwdRgx.test(field.value)
    };
    this.errorMessages = {
      required: this.requiredErrorValue,
      emailFormat: this.emailFormatErrorValue,
      mustMatch: this.mustMatchErrorValue,
      passwordFormat: this.passwordFormatErrorValue
    };
  }

  // Actions

  onBlur(e) {
    this.validateField(e.target);
    this.checkFormValidity();
  }

  handleSubmit(e) {
    if (!this.validateForm()) {
      e.preventDefault();
      e.stopPropagation();
      this.firstInvalidField.focus();
      return false;
    }
    return true;
  }

  // Validations

  checkFormValidity() {
    const result = this.validateForm();
    if (result) {
      this.dispatch('valid');
    } else {
      this.dispatch('invalid');
    }
  }

  validateForm() {
    let isValid = true;
    this.fieldTargets.forEach((field) => {
      if (this.shouldValidateField(field) && !this.validateField(field)) isValid = false;
    });
    return isValid;
  }

  validateField(field) {
    if (!this.shouldValidateField(field)) return true;

    const isValid = this.checkValidity(field);
    field.closest('.control-group').classList.toggle(this.errorClass, !isValid);
    const errorTextNode = field.closest('.control-group').querySelector('.error-text');
    if (!isValid) {
      if (errorTextNode) {
        this.refreshErrorText(field, errorTextNode);
        errorTextNode.classList.remove(this.displayNoneClass);
      } else if (field.type === 'checkbox') {
        field.closest('.controls').insertAdjacentHTML('beforeend', this.buildErrorNode(field));
      } else {
        field.insertAdjacentHTML('afterend', this.buildErrorNode(field));
      }
    } else if (errorTextNode !== null) {
      errorTextNode.classList.add(this.displayNoneClass);
    }
    return isValid;
  }

  shouldValidateField(field) {
    return field.dataset.validate;
  }

  checkValidity(field) {
    const errorArray = [];
    const validityArray = Object.entries(this.rules).map((rule) => {
      const [key, value] = rule;
      const valid = (!field.dataset[key]) ? true : value(field, field.dataset[key]);
      if (!valid) errorArray.push(key);
      return valid;
    });
    field.dataset.errorArray = errorArray;
    return validityArray.reduce((acc, val) => acc && val);
  }

  // Error message handling

  fieldErrorText(field) {
    return this.errorMessages[field.dataset.errorArray.split(',')[0]];
  }

  refreshErrorText(field, errorTextNode) {
    errorTextNode.innerHTML = this.fieldErrorText(field);
  }

  buildErrorNode(field) {
    const errorText = this.fieldErrorText(field);
    return `<div class="error-text">${errorText}</div>`;
  }

  get firstInvalidField() {
    return this.element.querySelector('.error-text:not(.d-none)').previousSibling;
  }
}
