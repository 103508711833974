import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['listType', 'onlyForTable', 'onlyForGrid']

  static classes = ['displayNone'];

  connect() {
    this.toggleVisibleFields();
  }

  // ACTIONS

  toggleVisibleFields() {
    if (this.listTypeTarget.value === 'grid') {
      this.onlyForTableTargets.forEach(el => el.classList.add(this.displayNoneClass));
      this.onlyForGridTargets.forEach(el => el.classList.remove(this.displayNoneClass));
    } else {
      this.onlyForGridTargets.forEach(el => el.classList.add(this.displayNoneClass));
      this.onlyForTableTargets.forEach(el => el.classList.remove(this.displayNoneClass));
    }
  }
}
