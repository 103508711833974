import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['submitButton'];

  // ACTIONS

  enable() {
    this.submitButtonTarget.removeAttribute('disabled');
  }

  disable() {
    this.submitButtonTarget.setAttribute('disabled', true);
  }
}
