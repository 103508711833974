import { Controller } from '@hotwired/stimulus';
import { get } from '../utils/axios_request';

export default class extends Controller {
  static targets = ['providerSelect', 'workflowSelect'];

  static values = {
    workflowsPath: String
  };

  // ACTIONS

  providerChanged() {
    if (this.providerSelectTarget.value === '') return;
    this.loadSigningWorkflows(this.providerSelectTarget.value).then(
      response => this.addSigningWorkflows(response.data)
    );
  }

  // PRIVATE

  loadSigningWorkflows(providerId) {
    return get(this.workflowsPathValue, { provider_id: providerId });
  }

  addSigningWorkflows(workflows) {
    this.workflowSelectTarget.options.length = 0;
    workflows.map(flow => new Option(flow.name, flow.id))
      .forEach(option => this.workflowSelectTarget.appendChild(option));
    this.workflowSelectTarget.disabled = false;
  }
}
