import { Controller } from '@hotwired/stimulus';
import { post } from '../utils/axios_request';

export default class extends Controller {
  static targets =
    ['modal', 'form', 'nameInput', 'submit', 'spinner', 'error'];

  static classes = ['displayNone', 'error'];

  static values = {
    duplicatedTranslation: String
  }

  // ACTIONS

  prepareModal(event) {
    this.clearErrors();

    const checklist = event.target.closest('.checklist-js');

    const { id, name } = checklist.dataset;

    this.modalTarget.dataset.baseChecklistId = id;
    this.nameInputTarget.value = `${name} - ${this.duplicatedTranslationValue}`;
  }

  formSubmit(event) {
    event.preventDefault();
    this.clearErrors();

    this.submitTarget.setAttribute('disabled', true);
    this.spinnerTarget.classList.remove(this.displayNoneClass);

    post(
      this.formTarget.action.replace('-id-', this.modalTarget.dataset.baseChecklistId),
      { name: this.nameInputTarget.value }
    ).then(() => {
      window.location.reload();
    }).catch(({ request }) => {
      const { message } = JSON.parse(request.response).errors.validation_error;

      this.appendError(message);
      this.spinnerTarget.classList.add(this.displayNoneClass);
      this.submitTarget.removeAttribute('disabled');
    });
  }

  // PRIVATE

  clearErrors() {
    this.errorTargets.forEach((el) => {
      el.remove();
    });
  }

  appendError(msg) {
    const div = document.createElement('div');
    div.classList.add(...this.errorClasses);
    div.dataset.checklistDuplicateTarget = 'error';
    div.innerHTML = msg;
    this.nameInputTarget.after(div);
  }
}
