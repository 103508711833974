import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['filterOption', 'resetFilter'];

  // ACTIONS

  searchEvent () {
    if (!this.filterOptionTarget.checked) {
      this.resetFilterTarget.value = true;
    }
    this.dispatch('search');
  }
}
