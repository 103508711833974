import u from 'umbrellajs';
import qs from 'qs';
import axios from 'axios';

const token = () => u('meta[name="csrf-token"]').attr('content');
const commonHeaders = () => ({
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-Token': token(),
  'Content-Type': 'application/json'
});

const get = function(url, params = {}, headersOverride = {}) {
  return axios({
    url,
    method: 'GET',
    headers: { ...commonHeaders(), ...headersOverride },
    params,
    paramsSerializer: prms => qs.stringify(prms, { arrayFormat: 'brackets' })
  });
};

const post = function(url, params) {
  return axios({
    url,
    method: 'POST',
    headers: { ...commonHeaders() },
    data: JSON.stringify(params)
  });
};

const put = function(url, params, headersOverride = {}) {
  return axios({
    url,
    method: 'PUT',
    headers: { ...commonHeaders(), ...headersOverride },
    data: JSON.stringify(params)
  });
};

const patch = function(url) {
  return axios({
    url,
    method: 'PATCH',
    headers: { ...commonHeaders() }
  });
};

const putFile = function (url, file, progressUpdate) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('authenticity_token', token());
  return axios({
    url,
    method: 'POST',
    data: formData,
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      progressUpdate(percentCompleted);
    }
  });
};

const putCacheImage = function (url, imageFileBlob, originalFilename) {
  const formData = new FormData();
  formData.append('file', imageFileBlob, originalFilename);
  formData.append('authenticity_token', token());
  return axios({
    url,
    method: 'PATCH',
    data: formData,
    headers: {
      Accept: 'application/json'
    }
  });
};

const destroy = function(url, params = {}) {
  return axios({
    url,
    method: 'DELETE',
    headers: { ...commonHeaders() },
    params,
    paramsSerializer: prms => qs.stringify(prms, { arrayFormat: 'brackets' })
  });
};

export { get, post, put, patch, putFile, putCacheImage, destroy }; // eslint-disable-line
