import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggled'];

  // ACTIONS

  toggle() {
    Array.from(this.toggledTargets).forEach((el) => {
      el.value = (el.value === 'false' ? 'true' : 'false');
    });
  }
}
