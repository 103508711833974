import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['filterOption', 'showMore', 'showLess'];

  static values = {
    amount: { type: Number, default: 3 }
  }

  connect () {
    this.hideFilterOptions();
  }

  // ACTIONS

  switchFilter() {
    this.toggleableOptions().forEach((elem) => {
      elem.hidden = !elem.hidden;
    });
    this.showMoreTarget.hidden = !this.showMoreTarget.hidden;
    this.showLessTargets.forEach(showLessElement => {
      showLessElement.hidden = !showLessElement.hidden;
    });
  }

  searchEvent() {
    this.dispatch('search');
  }

  showAll() {
    this.filterOptionTargets.forEach((elem) => {
      elem.hidden = false;
    });
    this.showMoreTarget.hidden = !this.showMoreTarget.hidden;
    this.showLessTargets.forEach(showLessElement => {
      showLessElement.hidden = !showLessElement.hidden;
    });
  }

  // PRIVATE

  toggleableOptions() {
    return this.filterOptionTargets.slice(this.amountValue);
  }

  hideFilterOptions() {
    if (this.filterOptionTargets.length > this.amountValue) {
      this.toggleableOptions().forEach((elem) => {
        elem.hidden = true;
      });
    } else {
      this.showMoreTarget.hidden = true;
    }
    this.showLessTargets.forEach(showLessElement => {
      showLessElement.hidden = true;
    });
  }
}
