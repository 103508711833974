import { Controller } from '@hotwired/stimulus';
import { BrowserDetect } from '../modules/browser_detect';

export default class extends Controller {
  static targets = [
    'fileGroup', 'fileName', 'removeGroup', 'fileAdder', 'destroyInput',
    'uploadInputsGroup', 'label'
  ];

  static classes = ['displayNone'];

  // CONTROLLER INIT

  connect() {
    const hiddenGroupsLength = this.visibilityInit();
    if (hiddenGroupsLength === 0) this.disableFileAdder();
  }

  visibilityInit() {
    const { fileNameTargets } = this;
    let hiddenGroupsLength = 0;
    Array.from(this.fileGroupTargets).forEach((el, i) => {
      if (fileNameTargets[i].textContent.trim() === '') {
        el.classList.add(this.displayNoneClass);
        hiddenGroupsLength += 1;
      }
    });
    return hiddenGroupsLength;
  }

  // ACTIONS

  addExtraFile() {
    const hidden = this.fileGroupTargets.map((el, i) => ({ el, i }))
      .filter(obj => obj.el.classList.contains(this.displayNoneClass));
    if (!hidden.length) return;

    this.showFileGroup(hidden[0]);
    if (hidden.length === 1) this.disableFileAdder();
  }

  removeExtraFile(event) {
    const { fileGroupTargets } = this;
    Array.from(this.removeGroupTargets).forEach((el, i) => {
      if (el === event.target) {
        fileGroupTargets[i].classList.add(this.displayNoneClass);
        this.wipeUploadData(i);
        this.enableFileAdder();
        if (BrowserDetect.browser() !== 'Internet Explorer') {
          this.moveFileGroups(i + 1);
        }
      }
    });
  }

  // HELPERS

  wipeUploadData(i) {
    this.clearAllInputValues(i);
    this.clearFileName(i);
    this.setDestroyInput(i, 1);
  }

  moveFileGroups(idx) {
    let i = idx;
    while (this.fileGroupTargets[i] !== undefined) {
      this.swapLabels(this.labelTargets[i - 1], this.labelTargets[i]);
      this.swapNodes(this.fileGroupTargets[i - 1], this.fileGroupTargets[i]);
      i += 1;
    }
  }

  swapLabels(prev, next) {
    const prevValue = prev.textContent;
    prev.textContent = next.textContent;
    next.textContent = prevValue;
  }

  swapNodes(prev, next) {
    const prevParent = prev.parentNode;
    const prevSibling = prev.nextSibling === next ? prev : prev.nextSibling;
    next.parentNode.insertBefore(prev, next);
    prevParent.insertBefore(next, prevSibling);
  }

  // DOM-MODIFIERS

  showFileGroup(fileGroupObject) {
    fileGroupObject.el.classList.remove(this.displayNoneClass);
    this.setDestroyInput(fileGroupObject.i, '');
  }

  clearAllInputValues(i) {
    Array.from(this.uploadInputsGroupTargets[i].childNodes).forEach((el) => {
      el.value = '';
    });
  }

  clearFileName(i) {
    this.fileNameTargets[i].textContent = '';
  }

  setDestroyInput(i, val) {
    this.destroyInputTargets[i].value = val;
  }

  enableFileAdder() {
    this.fileAdderTarget.setAttribute('disabled', false); // edge bug
    this.fileAdderTarget.removeAttribute('disabled'); // rest of the world
  }

  disableFileAdder() {
    this.fileAdderTarget.setAttribute('disabled', true);
  }
}
