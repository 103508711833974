import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tab'];

  connect() {
    const hash = window.location.hash.replace('#', '');
    Array.from(this.tabTargets).forEach((tab) => {
      if (tab.id === `${hash}-tab`) {
        tab.click();
      }
    });
  }
}
