import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['buttonIcon'];

  static classes = ['panel', 'displayNone'];

  // ACTIONS

  toggleMinimize() {
    document.querySelector(`.${this.panelClass}`).classList.toggle(this.displayNoneClass);
    Array.from(this.buttonIconTargets).forEach(b => b.classList.toggle(this.displayNoneClass));
  }
}
