import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['stateSelection', 'signedInput', 'notSignedInput'];

  connect() {
    if (this.signedInputTarget.checked) {
      this.stateSelectionTarget.value = 'signed';
    } else if (this.notSignedInputTarget.checked) {
      this.stateSelectionTarget.value = 'notSigned';
    }
  }

  // ACTIONS

  stateSelected() {
    this.uncheckAll();
    if (this.stateSelectionTarget.value === 'signed') {
      this.signedInputTarget.checked = true;
    } else if (this.stateSelectionTarget.value === 'notSigned') {
      this.notSignedInputTarget.checked = true;
    }
  }

  uncheckAll() {
    this.signedInputTarget.checked = false;
    this.notSignedInputTarget.checked = false;
  }
}
