import Bowser from 'bowser';

const BrowserDetect = {
  getInfo () { return Bowser.getParser(window.navigator.userAgent).parsedResult },
  browser () { return this.getInfo().browser.name },
  version () { return this.getInfo().browser.version },
  osname () { return this.getInfo().os.name }
};

function isBadBrowser() {
  if ((BrowserDetect.browser() === 'Internet Explorer') && (parseInt(BrowserDetect.version(), 10) <= 11)) {
    return true;
  }
  return false;
}

function setBadBrowserCookie(cookieName, value, expireHours) {
  const expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + (expireHours * 60 * 60 * 1000));
  document.cookie = cookieName + "=" + escape(value) + ";expires=" + expiryDate.toGMTString();
}

function getBadBrowserCookie(cookieName) {
  if (document.cookie.length > 0) {
    let cookieStart = document.cookie.indexOf(cookieName + "=");
    if (cookieStart !== -1) {
      cookieStart = cookieStart + cookieName.length + 1;
      let cookieEnd = document.cookie.indexOf(';', cookieStart);
      if (cookieEnd === -1) cookieEnd = document.cookie.length;
      return unescape(document.cookie.substring(cookieStart, cookieEnd));
    }
  }
  return '';
}

export {
  BrowserDetect, isBadBrowser, getBadBrowserCookie, setBadBrowserCookie
};
