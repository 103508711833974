import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'template', 'nestedFields', 'orderInput', 'destroyInput', 'destroyTrigger', 'sortableRoot'
  ];

  static values = {
    prefix: String
  };

  initialize() {
    if (this.prefixValue === null) this.prefixValue = ''; // ensure there is no "null" in regex
    if (this.hasSortableRootTarget) this.initSortable();
  }

  // ACTIONS

  addFields() {
    const content = this.templateTarget.innerHTML.replace(
      RegExp(`NEW_${this.prefixValue}_?RECORD`, 'gi'), new Date().getTime()
    );
    // it assumes that templateTarget is adjacent to inserted nested fields!
    this.templateTarget.parentNode.insertAdjacentHTML('beforeend', content);
    this.setOrder();
  }

  removeFields(e) {
    Array.from(this.destroyTriggerTargets).forEach((target, idx) => {
      if (e.target === target) {
        this.destroyFields(idx);
      }
    });
    this.setOrder();
  }

  // sorted event action
  updateOrder() {
    this.setOrder();
  }

  // PRIVATE

  destroyFields(idx) {
    const removedFields = this.nestedFieldsTargets[idx];
    if (removedFields.hasAttribute('data-new-record')) {
      removedFields.remove();
    } else {
      this.destroyInputTargets[idx].value = 1;
      removedFields.style.display = 'none';
    }
  }

  setOrder() {
    if (this.orderInputTargets.length === 0) return;
    Array.from(this.nestedFieldsTargets).forEach((_, idx) => {
      this.orderInputTargets[idx].value = idx;
    });
  }

  initSortable() {
    $(this.sortableRootTarget).sortable({
      update: () => this.dispatch('sorted'),
      axis: 'y',
      handle: '.js-handle',
      tolerance: 'pointer',
      placeholder: 'sortable-placeholder'
    });
  }
}
