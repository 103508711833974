import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["image", "nextImage", "text", "link"];
  static values = {
    index: Number,
    timeout: Number,
    images: Array,
    linkUrls: Array,
    newTabs: Array,
    linkTexts: Array,
    heights: Array,
    verticalPositions: Array
  }

  connect() {
    this.startRotation();
  }

  startRotation() {
    this.rotateImages();
    this.interval = setInterval(() => {
      this.rotateImages();
    }, (this.timeoutValue * 1000));
  }

  rotateImages() {
    if (this.imagesValue.length < 1) {
      return;
    }

    const nextIndex = (this.indexValue + 1) % this.imagesValue.length;
    const nextImageSrc = this.imagesValue[nextIndex];

    this.nextImageTarget.src = nextImageSrc;
    this.imageTarget.classList.add('fade-enter');

    if (this.heightsValue[nextIndex]) {
      switch (this.heightsValue[nextIndex]) {
        case 'small':
          this.imageTarget.style.height = '10vw';
          this.nextImageTarget.style.height = '10vw';
          break;
        case 'medium':
          this.imageTarget.style.height = '20vw';
          this.nextImageTarget.style.height = '20vw';
          break;
        case 'large':
          this.imageTarget.style.height = '30vw';
          this.nextImageTarget.style.height = '30vw';
          break;
        case 'full':
          this.imageTarget.style.height = 'auto';
          this.nextImageTarget.style.height = 'auto';
          break;
      }
    } else {
      this.imageTarget.style.height = 'auto';
      this.nextImageTarget.style.height = 'auto';
    }

    if (this.verticalPositionsValue[nextIndex]) {
      this.imageTarget.style.alignItems = this.verticalPositionsValue[nextIndex];
      this.nextImageTarget.style.alignItems = this.verticalPositionsValue[nextIndex];
    } else {
      this.imageTarget.style.alignItems = '';
      this.nextImageTarget.style.alignItems = '';
    }

    this.nextImageTarget.style.opacity = 1;

    this.imageTarget.style.opacity = 0;

    this.indexValue = nextIndex;

    if (this.textTarget && this.linkTextsValue) {
      this.textTarget.innerHTML = this.linkTextsValue[this.indexValue];
    }
    const currentLinkUrl = this.linkUrlsValue[this.indexValue];
    if (this.linkTarget) {
      if (currentLinkUrl) {
        this.linkTarget.href = currentLinkUrl;
        this.linkTarget.target = this.newTabsValue[this.indexValue] ? '_blank' : '';
        this.linkTarget.classList.remove('disabled-link');
      } else {
        this.linkTarget.removeAttribute('href');
        this.linkTarget.classList.add('disabled-link');
      }
    }

    setTimeout(() => {
      this.imageTarget.src = nextImageSrc;
      this.imageTarget.classList.remove('fade-enter');
      this.imageTarget.style.opacity = 1;
      this.nextImageTarget.style.opacity = 0;
    }, 1000);
  }

  disconnect() {
    this.stopRotation();
  }

  stopRotation() {
    clearInterval(this.interval);
  }
}
