import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['replacementParent'];

  static values = {
    id: Number,
    klass: String
  }

  connect() {
    this.subscribeToDocumentUpdates();
  }

  // PRIVATE

  subscribeToDocumentUpdates() {
    window.App.docs = window.App.cable.subscriptions.create({
      channel: 'DocumentsProcessingChannel', id: this.idValue, klass: this.klassValue
    },
    {
      received: (data) => {
        this.replacementParentTarget.innerHTML = data.link_html;
        window.renderFlashMessages(JSON.stringify(data.notification));
      }
    });
  }
}
