import { Controller } from '@hotwired/stimulus';
import { get, destroy } from '../utils/axios_request';

export default class extends Controller {
  static targets = ['confirmInput', 'confirmButton', 'loader'];

  static values = {
    confirmationText: String,
    destroyUrl: String,
    pollDestroyUrl: String
  };

  static classes = ['hidden'];

  initialize() {
    if (this.hasLoaderTarget) {
      $('#elvium-modal').on('hide.bs.modal', () => this.clearPolling());
    }
  }

  disconnect() {
    if (this.hasLoaderTarget) {
      this.clearPolling();
    }
  }

  // ACTIONS

  confirm() {
    this.destroyConfirmed();
  }

  blockPaste(e) {
    e.preventDefault();
    return false;
  }

  textChanged() {
    if (this.confirmInputTarget.value.trim(" ") === this.confirmationTextValue) {
      this.confirmButtonTarget.disabled = false;
    } else {
      this.confirmButtonTarget.disabled = true;
    }
  }

  // PRIVATE

  destroyConfirmed() {
    const params = { confirmation: this.confirmInputTarget.value };
    destroy(this.destroyUrlValue, params).then(() => {
      this.confirmButtonTarget.classList.add(this.hiddenClass);
      this.loaderTarget.classList.remove(this.hiddenClass);
      window.currentPolling = setInterval(() => {
        get(this.pollDestroyUrlValue).then((response) => {
          if (response.data.location) {
            window.location = response.data.location;
          }
        });
      }, 2000);
    }).catch((err) => {
      window.renderFlashFromResponse(err.response.data);
    });
  }

  clearPolling() {
    clearInterval(window.currentPolling);
  }
}
