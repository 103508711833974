import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['searchForm'];

  // ACTIONS

  search() {
    Rails.fire(this.searchFormTarget, 'submit');
  }
}
