import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'userIdInput', 'userNameField', 'userEmailField', 'userImageField',
    'userDetails', 'userImage', 'signerSelectContainer', 'signerTypeSelectContainer',
    'userAdditionals', 'userPhoneInput', 'userCvrInput', 'externalInputs'
  ];

  static values = {
    data: Array
  }

  static classes = ['hidden'];

  // ACTIONS

  signerTypeSelected(e) {
    const signerType = e.currentTarget.value;
    if (signerType === 'external') {
      this.showExternalInputs();
    } else if (signerType === 'internal') {
      this.showSignerSelect();
    }
  }

  signerSelected(e) {
    const userId = e.currentTarget.value;
    this.setUserId(userId);
    this.updateFullDataAndShowSigner(userId);
  }

  // PRIVATE

  showExternalInputs() {
    this.signerTypeSelectContainerTarget.classList.add(this.hiddenClass);
    this.externalInputsTarget.classList.remove(this.hiddenClass);
    if (this.hasUserAdditionalsTarget) {
      this.userAdditionalsTarget.classList.remove(this.hiddenClass);
    }
  }

  showSignerSelect() {
    this.signerTypeSelectContainerTarget.classList.add(this.hiddenClass);
    this.signerSelectContainerTarget.classList.remove(this.hiddenClass);
  }

  setUserId(userId) {
    this.userIdInputTarget.value = userId;
  }

  updateFullDataAndShowSigner(userId) {
    const fullData = this.dataValue.map(o => JSON.parse(o));
    const selectedUserData = fullData.find(e => e.id === parseInt(userId, 10));
    this.showSigner(selectedUserData);
  }

  showSigner(data) {
    this.fillSignerData(data);
    this.swapSelectToForm();
  }

  fillSignerData(data) {
    this.userNameFieldTarget.innerHTML = data.name;
    this.userEmailFieldTarget.innerHTML = data.email;
    this.userImageFieldTarget.src = data.photo;
    if (this.hasUserPhoneInputTarget) this.userPhoneInputTarget.value = data.phone;
    if (this.hasUserCvrInputTarget) this.userCvrInputTarget.value = data.cvr;
  }

  swapSelectToForm() {
    this.signerSelectContainerTarget.classList.add(this.hiddenClass);
    this.userDetailsTarget.classList.remove(this.hiddenClass);
    this.userImageTarget.classList.remove(this.hiddenClass);
    if (this.hasUserAdditionalsTarget) {
      this.userAdditionalsTarget.classList.remove(this.hiddenClass);
    }
  }

  checkboxSameSignerClicked(e) {
    if (e.target.checked) {
      $('button[name="add_signer_button"]').hide();
      $('.additional-signer').hide();
    } else {
      $('button[name="add_signer_button"]').show();
      $('.additional-signer').show();
    }
  }

  checkboxSameContractClicked(e) {
    if (e.target.checked) {
      localStorage.setItem('singleContract', true);
      window.frEditors.forEach(function(editor) {
        editor.html.set('')
      });
      $('.card-body:not(:first)').hide();
    } else {
      localStorage.setItem('singleContract', false);
      window.frEditors.forEach(function(editor) {
        editor.html.set('')
      });
      $('.card-body:not(:first)').show();
    }
  }
}
