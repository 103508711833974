import { Controller } from '@hotwired/stimulus';
import { post } from '../utils/axios_request';

export default class extends Controller {
  static targets = [
    'loader', 'submitButton', 'resultsWrapper', 'results', 'finishForm', 'consent',
    'operationType', 'position'
  ];

  static classes = ['displayNone', 'success', 'failure'];

  static values = {
    url: String,
    sourceApplicationIds: Array,
    sourceArtificialCandidateIds: Array
  };

  // ACTIONS

  positionSelected(e) {
    this.hideWrappers();
    this.hideFinishForm();
    const select = e.target;
    const targetId = this.positionTarget.options[this.positionTarget.selectedIndex].value;
    const opType = this.operationTypeTarget.options[this.operationTypeTarget.selectedIndex].value;
    if (targetId === '' || opType === '') return;
    this.toggleLoadingState(select);
    post(this.urlValue, {
      application_transfer_operation: {
        target_position_id: targetId,
        source_application_ids: this.sourceApplicationIdsValue,
        source_artificial_candidate_ids: this.sourceArtificialCandidateIdsValue,
        operation_type: opType
      }
    }).then((response) => {
      this.cleanPreviousResults();
      this.showTransferCheckResults(response.data);
      this.unhideWrappers();
      this.enableFinishForm(response.data.map(r => r.enable_form));
    }).finally(() => {
      this.toggleLoadingState(select);
    });
  }

  checkConsent(e) {
    if (e.target.checked) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }

  // PRIVATE

  toggleLoadingState(select) {
    this.loaderTarget.classList.toggle(this.displayNoneClass);
    select.toggleAttribute('disabled');
  }

  cleanPreviousResults() {
    Array.from(this.resultsTargets).forEach(
      (t) => { if (t.innerHTML !== '') t.remove(); }
    );
  }

  showTransferCheckResults(data) {
    data.forEach((appResult) => {
      const th = this;
      const span = th.resultsTargets.find(
        t => parseInt(t.dataset.id, 10) === appResult.id
      );
      const parent = this.resultsWrapperTargets.find(
        t => parseInt(t.dataset.id, 10) === appResult.id
      );
      if (appResult.success) {
        const successSpan = span.cloneNode(true);
        successSpan.classList.add(this.successClass);
        [successSpan.innerHTML] = appResult.messages;
        parent.appendChild(successSpan);
      } else {
        appResult.messages.forEach((msg) => {
          const failureSpan = span.cloneNode(true);
          failureSpan.classList.add(this.failureClass);
          failureSpan.innerHTML = msg;
          parent.appendChild(failureSpan);
        });
      }
    });
  }

  hideFinishForm() {
    this.finishFormTarget.classList.add(this.displayNoneClass);
    this.consentTarget.checked = false;
  }

  enableFinishForm(results) {
    if (results.includes(false)) return;

    this.finishFormTarget.classList.remove(this.displayNoneClass);
  }

  hideWrappers() {
    Array.from(this.resultsWrapperTargets).forEach(
      t => t.classList.add(this.displayNoneClass)
    );
  }

  unhideWrappers() {
    Array.from(this.resultsWrapperTargets).forEach(
      t => t.classList.remove(this.displayNoneClass)
    );
  }
}
