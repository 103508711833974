import { Controller } from '@hotwired/stimulus';
import { get } from '../utils/axios_request'
import { Routes } from '../routes/index.js.erb'

export default class extends Controller {
  static targets = ['input'];

  selectFeedbackForm() {
    const selectedFeedbackId = this.inputTarget.value;
    get(Routes.brief_feedback_form_path(selectedFeedbackId)).then((response) => {
      const result = response.data;
      const briefContent = document.getElementsByName('brief[content]')[0];
      const briefId = document.getElementsByName('brief[id]')[0];
      const briefView = document.getElementsByClassName('fr-element')[0];
      briefContent.value = result.content
      briefView.innerHTML = result.content
      briefId.value = result.id
      const briefPlaceholder = document.getElementsByClassName('fr-placeholder')[0];
      briefPlaceholder.innerHTML = '';
    })
  }
}
