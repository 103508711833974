import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'primaryFontFamilyInput', 'secondaryFontFamilyInput', 'footerTextInput',
    'footerTextColorInput', 'footerBgColorInput',
    'manualRegisterInput', 'linkedinRegisterInput', 'facebookRegisterInput',
    'jobindexRegisterInput', 'bannerInput'
  ];

  static values = {
    templatesData: Array
  };

  // APC - Application Process Customization

  connect() {
    this.parsedTemplatesData = this.templatesDataValue.map(t => JSON.parse(t));
  }

  // ACTIONS

  copy(e) {
    const selectedTemplateId = e.target.options[e.target.selectedIndex].value;
    if (selectedTemplateId) {
      const data = this.getTemplateData(selectedTemplateId);

      this.copyBasicInputs(data);
      this.copyBannerFile(data);
    }
  }

  // PRIVATE

  getTemplateData(selectedTemplateId) {
    return this.parsedTemplatesData.filter(t => t.id === parseInt(selectedTemplateId, 10))[0];
  }

  copyBasicInputs(data) {
    this.primaryFontFamilyInputTarget.value = data.primary_font_family;
    this.secondaryFontFamilyInputTarget.value = data.secondary_font_family;
    this.footerTextInputTarget.value = data.footer_text;
    $(this.footerTextColorInputTarget).spectrum('set', data.footer_text_color);
    $(this.footerBgColorInputTarget).spectrum('set', data.footer_bg_color);
    this.manualRegisterInputTarget.value = data.manual_register;
    this.linkedinRegisterInputTarget.value = data.linkedin_register;
    this.facebookRegisterInputTarget.value = data.facebook_register;
    this.jobindexRegisterInputTarget.value = data.jobindex_register;
  }

  copyBannerFile(data) {
    // this value goes to backend after form submit
    this.bannerInputTarget.value = data.jq_upload.url;
    // those values are purely for image preview to appear
    data.jq_upload.image_cache = data.jq_upload.url;
    if (data.jq_upload.url === null || data.jq_upload.url === undefined) {
      $('.js-fileupload').trigger('fileuploaddestroy');
    } else {
      $('.js-fileupload').trigger('fileuploaddone', { result: JSON.stringify([data.jq_upload]) });
    }
  }
}
