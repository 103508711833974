import { throttle } from 'lodash';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['topButton'];

  static classes = ['fadeIn', 'fadeOut'];

  connect() {
    const callback = this.scrollCallback.bind(this);
    // guaranteed execution every 250ms
    window.addEventListener('scroll', throttle(callback, 250));
  }

  scrollCallback() {
    const MIN_SCROLL = 370;
    if (window.scrollY > MIN_SCROLL) {
      this.topButtonTarget.classList.add(this.fadeInClass);
      this.topButtonTarget.classList.remove(this.fadeOutClass);
    } else {
      this.topButtonTarget.classList.add(this.fadeOutClass);
      this.topButtonTarget.classList.remove(this.fadeInClass);
    }
  }

  // ACTIONS

  returnToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
