import { Controller } from '@hotwired/stimulus';
import u from 'umbrellajs';
import './clipboard_controller.scss';

export default class extends Controller {
  static targets = ['source'];

  static classes = ['supported'];

  connect() {
    if (document.queryCommandSupported('copy')) {
      this.element.classList.add(this.supportedClass);
    }
  }

  // ACTIONS

  copy(e) {
    e.preventDefault();
    this.copyTextToClipboard(this.sourceTarget.value);
    return false;
  }

  // PRIVATE

  fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    const elem = u(this.sourceTarget);
    textArea.value = text;
    elem.parent().append(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      window.renderFlashMessages({ notice: 'Copy ' + msg });
    } catch (err) {
      window.renderFlashMessages({ alert: 'Oops, unable to copy ' + err });
    }

    textArea.remove();
  }

  copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      window.renderFlashMessages({ notice: 'Copy successful' });
    }, (err) => {
      window.renderFlashMessages({ alert: 'Oops, unable to copy ' + err });
    });
  }
}
