import { Controller } from '@hotwired/stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['form'];

  // ACTIONS

  submitRemoteForm() {
    Rails.fire(this.formTarget, 'submit');
  }

  submitRegularForm() {
    this.formTarget.submit();
  }
}
