import { Controller } from '@hotwired/stimulus';
import u from 'umbrellajs';
import { putFile } from '../utils/axios_request';

export default class extends Controller {
  static targets = ['fileInput', 'fileInputCache', 'validationStatus', 'fileName', 'progressIndicator', 'progressIndicatorWrapper', 'filePickersGroup'];
  static values = { url: String };

  connect() {
    this.checkSizeAndExtension = window.FileUpload.checkSizeAndExtension;
  }

  triggerFileSelection() {
    this.fileInputTarget.click();
    $(this.fileInputTarget).on("cancel", () => { this.cleanupAfterUpload(); });
    setTimeout(() => {
      this.disablePickers();
    }, 300);
  }

  async fileUploaded() {
    let fileInput = this.fileInputTarget.files[0];

    let blob = new Blob([await fileInput.arrayBuffer()], {
      type: fileInput.type
    });

    const buffer = await blob.arrayBuffer();
    let file = new File([buffer], fileInput.name, { type: fileInput.type });
    file = this.renameFile(file);

    try {
      await file.slice(0, 1).arrayBuffer();
      if (!this.isValid()) return;
      this.prepareForUpload();
      await this.uploadFile(file);
      this.cleanupAfterUpload();
    } catch (error) {
      console.log(error);
      this.cleanupAfterUpload();
      this.handleFileError('error-wrong-source');
    }
  }

  prepareForUpload() {
    this.disablePickers();
    this.dispatch('start');
    this.setValidationStatus('');
  }

  async uploadFile(file) {
    try {
      const response = await putFile(this.urlValue, file, this.updateProgress.bind(this));
      this.handleSuccessfulUpload(response);
    } catch (e) {
      this.handleRequestError(e);
      this.clearLocalInput();
    }
  }

  handleSuccessfulUpload(response) {
    const cache = response.data.cache_path;
    this.fileInputCacheTarget.value = cache;
    this.clearLocalInputSiblings();
    this.displayFileName();
  }

  cleanupAfterUpload() {
    this.enablePickers();
    this.dispatch('finish');
  }

  isValid() {
    const result = this.checkSizeAndExtension(this.fileInputTarget);
    if (result.hasErrors) {
      this.handleFileError(result.errorId);
      this.cleanupAfterUpload();
      return false;
    }
    return true;
  }

  handleFileError(errorId) {
    this.setValidationStatus(this.getErrorMessage(errorId));
    this.clearLocalInput();
  }

  handleRequestError(e) {
    const errorMap = {
      400: 'error-general-network',
      422: 'error-corrupted',
      406: e.response.data.error
    };
    this.setValidationStatus(this.getErrorMessage(errorMap[e.response.status] || 'error-unknown'));
  }

  getErrorMessage(errorId) {
    return document.getElementById(errorId).textContent.replace(/%\(file\)/, this.fileName);
  }

  uploadsInProgress() {
    return u('.js-uploading').filter(el => !el.classList.contains(this.displayNoneClass));
  }

  renameFile(file) {
    this.fileName = file.name.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9._-]/g, '_');
    const blob = file.slice(0, file.size, file.type);
    return new File([blob], this.fileName, { type: file.type });
  }

  // DOM Manipulation Methods

  setValidationStatus(value) {
    this.validationStatusTarget.textContent = value;
  }

  clearLocalInput() {
    this.fileInputTarget.value = '';
  }

  clearLocalInputSiblings() {
    // when local file upload succeeds we should not accept data from GDrive or Dropbox
    $(`#${this.fileInputCacheTarget.id.replace('_cache', '')}`).val('');
    u(this.fileInputCacheTarget).siblings().each(() => { this.element.value = '' });
  }

  displayFileName() {
    this.fileNameTarget.textContent = this.fileName;
  }

  enablePickers() {
    this.togglePickerState(false);
  }

  disablePickers() {
    this.togglePickerState(true);
  }

  togglePickerState(disabled) {
    Array.from(this.filePickersGroupTarget.querySelectorAll('button')).forEach((el) => {
      if (disabled) {
        el.setAttribute('disabled', 'disabled');
      } else {
        el.removeAttribute('disabled');
      }
    });

    if (disabled) {
      this.progressIndicatorWrapperTarget.classList.remove("d-none");
    } else {
      this.progressIndicatorWrapperTarget.classList.add("d-none");
    }
  }

  updateProgress(currentValue) {
    this.progressIndicatorTarget.setAttribute('value', currentValue);
  }
}
