import { Controller } from '@hotwired/stimulus';
import u from 'umbrellajs';
import { get } from '../utils/axios_request';

export default class extends Controller {
  static targets = ['table', 'input'];

  static values = {
    url: String
  };

  connect() {
    this.loadTableData();
  }

  // ACTIONS

  linkClicked(e) {
    e.preventDefault();
    let a = u(e.target);
    if (!a.is('a')) a = a.parent('a');
    this.loadTableData(a.attr('href'));
    return false;
  }

  search(e) {
    e.preventDefault();
    const input = u(this.inputTarget);
    this.loadTableData(null, { search: input.first().value });
    return false;
  }

  // PRIVATE

  loadTableData(dataUrl, params = {}) {
    let url = dataUrl;
    if (!url) url = this.urlValue;

    window.disposeTooltips();

    const container = u(this.tableTarget);
    container.html('<div class="loader"></div>');
    get(
      url, params, { Accept: 'text/javascript, application/javascript' }
    ).then((response) => {
      const script = document.createElement('script');
      script.text = response.data;
      document.head.appendChild(script).parentNode.removeChild(script);
      const pagination = container.find('div.pagination');
      if (pagination && pagination.length) {
        let footer = container.parent().find('.panel-footer');
        if (footer.length === 0) {
          container.parent().append('<div class="panel-footer"> </div>');
          footer = container.parent().find('.panel-footer');
        }
        footer.html(pagination.html());
        pagination.remove();
        footer.find('a').each((node) => {
          const a = u(node);
          a.data('action', 'click->table-data-loader#linkClicked');
        });
      }

      const sort = container.find('.table-head');
      if (sort && sort.length) {
        sort.find('a').each((node) => {
          const a = u(node);
          a.data('action', 'click->table-data-loader#linkClicked');
        });
      }

      window.assignTooltips();
    });
  }
}
