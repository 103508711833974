import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dueDaysInput', 'startDateRelationSelector'];

  connect () {
    this.handleChange();
  }

  handleChange() {
    if (this.startDateRelationSelectorTarget.value == 'first_day') {
      this.dueDaysInputTarget.value = null;
      this.dueDaysInputTarget.classList.remove('checklist-task-due-days');
      this.dueDaysInputTarget.classList.add('hide-checklist-task-due-days');
    } else {
      this.dueDaysInputTarget.classList.remove('hide-checklist-task-due-days')
      this.dueDaysInputTarget.classList.add('checklist-task-due-days')
    }
  }
}
