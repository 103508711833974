import { Controller } from '@hotwired/stimulus';
import escapeRegex from '../helpers/regex_escaping_helpers';

export default class extends Controller {
  static targets = ['tagsInput', 'storageInput', 'tag'];

  static classes = ['tagName', 'displayNone'];

  // ACTIONS

  tagConfirm(e) {
    const { keyCode } = e;
    // tab, enter or comma
    if (keyCode === 9 || keyCode === 13 || keyCode === 188) {
      e.preventDefault();
      e.stopPropagation();
      const newHobbyTag = this.tagsInputTarget.value;
      const existingTagValues = Array.from(this.tagTargets).map(
        t => t.querySelector(`.${this.tagNameClass}`).innerText
      );
      if (newHobbyTag.length > 0 && !existingTagValues.includes(newHobbyTag)) {
        this.tagsInputTarget.value = '';
        this.storeTag(newHobbyTag);
        this.addTagToContainer(newHobbyTag);
      }
    }
  }

  removeTag(e) {
    if (e.target.tagName === 'BUTTON') {
      const removedValue = e.currentTarget.querySelector(`.${this.tagNameClass}`).innerText;
      const freshValue = this.storageInputTarget.value.replace(
        new RegExp(escapeRegex(removedValue) + ',?'), ''
      );
      this.storageInputTarget.value = freshValue;
      e.currentTarget.remove();
    }
  }

  // HELPER FUNCTIONS

  storeTag(newHobbyTag) {
    const existingTags = this.storageInputTarget.value;
    this.storageInputTarget.value = existingTags + `, ${newHobbyTag}`;
  }

  addTagToContainer(newHobbyTag) {
    // first element is always mock for copying
    const newTagNode = Array.from(this.tagTargets)[0].cloneNode(true);
    newTagNode.classList.remove(this.displayNoneClass);
    newTagNode.querySelector(`.${this.tagNameClass}`).innerText = newHobbyTag;
    const [lastTagNode] = Array.from(this.tagTargets).slice(-1);
    lastTagNode.after(newTagNode);
  }
}
